import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { logoutUser, loginUser } from '../userSlice';
import { signIn, getUser } from '../services/userService';
import constants from '../helpers/constants';
import { useHistory } from 'react-router-dom';

export default function useAuth() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  async function login(credentials) {
    try {
      const loginResponse = await signIn(credentials);

      if (loginResponse.status === 401) {
        toast.error(loginResponse.body.error);
      }

      if (loginResponse.status === 200) {
        window.localStorage.setItem(
          constants.LOCALSTORAGE_KEY,
          loginResponse.body.token
        );
        const userResponse = await getUser();
        if (userResponse.status === 200) {
          dispatch(loginUser(userResponse.body));
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  function logout() {
    window.localStorage.removeItem(constants.LOCALSTORAGE_KEY);
    dispatch(logoutUser());
    history.push('/');
  }

  function rehydrateUser(setLoading) {
    console.log('rehydrate user');
    if (!isLoggedIn) {
      getUser()
        .then((data) => {
          setLoading(false);
          if (data.status === 200) {
            dispatch(loginUser(data?.body)), (error) => console.error(error);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  }

  return {
    login,
    logout,
    rehydrateUser,
  };
}
