import React from 'react';
import styled, { keyframes } from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { MdClose } from 'react-icons/md';

const slideIn = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0.8;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const ModalOverlayStyles = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: hsla(210deg, 15%, 6.25%, 0.5);
  display: grid;
  place-items: center;
`;

const ModalContentStyles = styled(DialogContent)`
  max-width: 450px;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fff;
  border-radius: 8px;
  animation: ${slideIn} 350ms;
  position: relative;

  p {
    margin: 0;
    font-size: 20px;
    font-family: var(--font-family);
  }

  .close {
    font-family: var(--font-family);
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 5px;
    line-height: 1;
    transition: all 0.2s;

    &:hover {
      background-color: hsl(var(--color-primary));
      svg {
        fill: #fff;
      }
    }

    svg {
      fill: #adadb0;
    }
  }
`;

export default function ReusableModal({ isOpen, close, children }) {
  return (
    <>
      <ModalOverlayStyles isOpen={isOpen} onDismiss={close}>
        <ModalContentStyles aria-label='Warning about deletion'>
          {children}
          <div className='close' onClick={close}>
            <MdClose size={30} />
          </div>
        </ModalContentStyles>
      </ModalOverlayStyles>
    </>
  );
}
