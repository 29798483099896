import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Switch = styled.label`
  position: relative;
  display: block;
  width: 60px;
  height: 34px;

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.2s cubic-bezier(0.7, 0.07, 0.71, 0.84);
      border-radius: 50%;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + span {
    background-color: hsl(var(--color-secondary));
  }

  input:focus + span {
    box-shadow: 0 0 1px hsl(var(--color-secondary));
  }

  input:checked + span::before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const ToggleSwitch = ({ register, name }) => {
  return (
    <>
      <Switch>
        <input type='checkbox' {...register(name)} />
        <span></span>
      </Switch>
    </>
  );
};

export default ToggleSwitch;
