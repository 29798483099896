const isDevelopment = process.env.NODE_ENV === 'development';
const authServerUrl =
  'https://fuve-auth-server.herokuapp.com/api/v1/fuve-auth-server';
const devAuthServerUrl =
  'https://staging-fuve-auth-server.herokuapp.com/api/v1/fuve-auth-server';

const devUpdateServerUrl = 'http://192.168.0.125:8080/api/v1';

// const updateServerUrl = 'http://165.22.196.96/api/v1';
const updateServerUrl = 'https://update.adflyer.eu/api/v1';

export default {
  isDevelopment,
  BASE_AUTH_URL: isDevelopment ? devAuthServerUrl : authServerUrl,
  UPDATE_SERVER_URL: isDevelopment ? devUpdateServerUrl : updateServerUrl,
  ACTIVATION_URL: `${
    isDevelopment ? devAuthServerUrl : authServerUrl
  }/registrations/company/validation/details`,
  TOKEN_VALIDATION_URL: `${
    isDevelopment ? devAuthServerUrl : authServerUrl
  }/registrations/company/validation/activation-token`,
  INVITE_URL: `${
    isDevelopment ? devAuthServerUrl : authServerUrl
  }/registrations/company/validation/invite`,
};
