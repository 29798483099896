import React from 'react';
import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import { useHistory, Link } from 'react-router-dom';

export default function ActivationSuccess() {
  const { width, height } = useWindowSize();
  const history = useHistory();
  const [counter, setCounter] = React.useState(25);

  React.useEffect(() => {
    const countDown = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);

    return () => clearInterval(countDown);
  }, [history, counter]);

  setTimeout(() => {
    history.push('/invite');
  }, 25000);

  return (
    <>
      <Confetti width={width} height={height} />
      <div
        style={{
          minHeight: '100vh',
          display: 'grid',
          placeItems: 'center',
          textAlign: 'center',
        }}
      >
        <div>
          <h2>
            <strong>Your Company Activation was successful!</strong>
          </h2>
          <hr />
          <h4>On the next page you can invite your colleagues to your team!</h4>
          <small>
            You&apos;ll be redirected to the next page in {counter} seconds.
            <span>
              &nbsp;
              <Link to='/invite' style={{ color: 'inherit' }}>
                (skip now)
              </Link>
            </span>
          </small>
        </div>
      </div>
    </>
  );
}
