import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  padding: 8px 16px;
  background-color: hsl(var(--color-primary));
  color: var(--color-white);
  cursor: pointer;
  border-radius: 8px;
  font-family: var(--font-family);
  font-size: 16px;
  text-transform: uppercase;
  box-shadow: var(--box-shadow-1);

  &:disabled {
    box-shadow: none;
    background-color: lightgray;
    cursor: auto;
  }

  &:disabled:hover {
    transform: revert;
  }
`;
