import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  MdUpdate,
  MdCardTravel,
  MdHome,
  MdCloudUpload,
  MdImportantDevices,
} from 'react-icons/md';
import { FaLayerGroup } from 'react-icons/fa';

const menuItems = [
  {
    name: 'Home',
    icon: MdHome,
    to: '/',
  },
  {
    name: 'Upload',
    icon: MdCloudUpload,
    to: '/upload',
  },
  {
    name: 'Updates',
    icon: MdUpdate,
    to: '/updates',
  },
  {
    name: 'Devices',
    icon: MdImportantDevices,
    to: '/devices',
  },
  {
    name: 'Groups',
    icon: FaLayerGroup,
    to: '/groups',
  },
  {
    name: 'Company Register',
    icon: MdCardTravel,
    to: '/company-register',
  },
];

const NavStyles = styled.nav`
  background-color: hsl(var(--color-primary));
  color: var(--color-white);
  padding: 1rem;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--drawer-width);
  /* for responsiveness */
  /* overflow-x: hidden; */

  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  a {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    color: inherit;
    text-decoration: none;

    &:hover {
      background-color: hsl(var(--color-secondary));
    }
  }

  li.selected {
    background-color: hsl(var(--color-secondary));
    margin-right: -1rem;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

export default function Navigation() {
  return (
    <>
      <NavStyles>
        <ul>
          {menuItems.map((item, i) => (
            <MenuItem key={i} to={item.to} name={item.name} Icon={item.icon} />
          ))}
        </ul>
      </NavStyles>
    </>
  );
}

function MenuItem({ to, name, Icon }) {
  const { pathname } = useLocation();
  const isSelected = pathname === to;

  return (
    <>
      <li className={`${isSelected && 'selected'}`}>
        <Link to={to}>
          <Icon color='var(--color-white)' size={25} />
          <p>{name}</p>
        </Link>
      </li>
    </>
  );
}
