import { client } from '../api/api-client';
import config from '../config';

function getDevices(currentPage = 1, limit = 10) {
  return client(
    config.UPDATE_SERVER_URL,
    `devices?page=${currentPage}&limit=${limit}`
  );
}

export { getDevices };
