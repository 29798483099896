import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import TransferList from '../components/TransferList';
import { getSingleGroup, deleteGroup } from '../services/groupService';
import { getDevices } from '../services/deviceService';
import ReusableModal from '../components/ReusableModal';
import { Button } from '../components/styles/Button';

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid hsl(var(--color-primary) / 50%);
`;

const Version = styled.h3`
  font-size: ${20 / 16}rem;
  span {
    font-weight: 700;
  }
`;

const BackButton = styled(Button)`
  background-color: hsl(var(--color-secondary));
`;

const DeleteButton = styled(Button)`
  font-weight: 500;
  border: 2px solid;
  background-color: var(--color-white);
  color: hsl(var(--color-error));
  border-color: hsl(var(--color-error));
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: hsl(var(--color-error));
    color: var(--color-white);
  }
`;

export default function GroupDetailPage() {
  const history = useHistory();
  const { groupId } = useParams();
  const [singleGroup, setSingleGroup] = useState({});
  const [devices, setDevices] = useState([]);
  const [groupDevices, setGroupDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const isDeviceChanged = useSelector((state) => state.group.isDevicesChanged);
  const [groupToBeDeleted, setGroupToBeDeleted] = useState(null);
  const [groupNameToBeDeleted, setGroupNameToBeDeleted] = useState(null);

  useEffect(() => {
    const singleGroup = getSingleGroup(groupId);
    const devices = getDevices();

    Promise.all([singleGroup, devices])
      .then(([singleGroupRes, devicesRes]) => {
        if (singleGroupRes.status === 200 && devicesRes.status === 200) {
          const groupDevices = singleGroupRes.body.group.devices;
          setSingleGroup(singleGroupRes.body.group);
          setDevices(devicesRes.body.devices);
          setGroupDevices(groupDevices);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [groupId]);

  function openDeletionModal(group) {
    setIsDeleteModalOpen(true);
    setGroupToBeDeleted(group._id);
    setGroupNameToBeDeleted(group.name);
  }

  function closeDeletionModal() {
    setIsDeleteModalOpen(false);
    setGroupToBeDeleted(null);
    setGroupNameToBeDeleted(null);
  }

  function handleDelete() {
    deleteGroup(groupToBeDeleted)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            `Group named "${groupNameToBeDeleted}" was successfully deleted`
          );
          setIsModalOpen(false);
          history.push('/groups');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <Layout>
        {loading && <Loader />}
        {!loading && (
          <>
            <HeaderWrapper>
              <h1>{singleGroup.name}</h1>
              <Version>
                Version:{' '}
                <span>
                  {`${singleGroup?.version?.name || ''} (${
                    singleGroup?.version?.version || ''
                  }`}
                  )
                </span>
              </Version>
              <BackButton
                onClick={
                  isDeviceChanged
                    ? () => setIsModalOpen(true)
                    : () => history.push('/groups')
                }
              >
                Back
              </BackButton>
              <DeleteButton onClick={() => openDeletionModal(singleGroup)}>
                Delete Group
              </DeleteButton>
            </HeaderWrapper>
            <h2>Devices:</h2>
            <TransferList
              devices={devices}
              groupDevices={groupDevices}
              groupId={singleGroup._id}
            />
          </>
        )}
      </Layout>
      <ReusableModal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
        <p>You have unsaved changes. Do you want to discard them?</p>
        <div style={{ display: 'flex', marginTop: '1rem', gap: '1rem' }}>
          <Button
            onClick={() => setIsModalOpen(false)}
            style={{
              border: '1px solid hsl(var(--color-primary))',
              backgroundColor: 'var(--color-white)',
              color: 'hsl(var(--color-primary))',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => history.push('/groups')}
            style={{ border: '1px solid hsl(var(--color-primary))' }}
          >
            Discard Changes
          </Button>
        </div>
      </ReusableModal>
      <ReusableModal
        isOpen={isDeleteModalOpen}
        close={() => closeDeletionModal()}
      >
        <p>{`Are you sure you want to delete group "${groupNameToBeDeleted}"?`}</p>
        <div style={{ display: 'flex', marginTop: '1rem', gap: '1rem' }}>
          <Button
            onClick={() => closeDeletionModal()}
            style={{
              border: '1px solid hsl(var(--color-primary))',
              backgroundColor: 'var(--color-white)',
              color: 'hsl(var(--color-primary))',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            style={{ border: '1px solid hsl(var(--color-primary))' }}
          >
            Delete
          </Button>
        </div>
      </ReusableModal>
    </>
  );
}
