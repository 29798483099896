import constants from '../helpers/constants';
import { toast } from 'react-toastify';
import { errorMessages } from '../helpers/constants';

export function client(server, endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(constants.LOCALSTORAGE_KEY);
  const headers = { 'Content-Type': 'application/json' };
  if (token) {
    headers.Authorization = token;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body && config.headers['Content-Type'] === 'application/json') {
    config.body = JSON.stringify(body);
  } else if (body) {
    config.body = body;
  }

  return window
    .fetch(`${server}/${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        logout();
        const body = await response.json();
        return {
          status: response.status,
          body,
        };
      }

      if (response.ok) {
        const body = await response.json();
        return {
          status: response.status,
          body,
        };
      } else {
        const errorMessage = await response.text();
        return Promise.reject(new Error(errorMessage));
      }
    })
    .catch((err) => {
      console.error(err);
      const errorMessage = JSON.parse(err.message).error;
      toast.error(errorMessages[errorMessage]);
    });
}

function logout() {
  window.localStorage.removeItem(constants.LOCALSTORAGE_KEY);
}
