import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { updateGroup } from '../services/groupService';
import { setIsDevicesChanged } from '../slices/groupSlice';
import { Button } from './styles/Button';

const TransferListWrapper = styled.div`
  display: flex;
  gap: 2rem;
  max-width: 900px;
  margin: 2rem 0;
`;

const BoxWrapper = styled.div`
  flex: 1;
  border-radius: 8px;
  background-color: var(--color-white);
  padding: 1rem;
  min-height: 300px;
  box-shadow: var(--box-shadow-1);
`;

const Box = styled.div``;

const BoxLabel = styled.h3`
  font-size: ${20 / 16}rem;
  margin-bottom: 1rem;
  font-weight: 700;
  /* optical alignment */
  margin-top: -5px;
`;

const Arrows = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
`;

const ArrowButton = styled(Button)`
  width: 50px;
  display: grid;
  place-content: center;

  svg {
    --icon-size: 25px;
    display: block;
    width: var(--icon-size);
    height: var(--icon-size);
    stroke-width: 4px;
  }
`;

const TransferListStyles = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ListItem = styled.li`
  padding: 12px;
  border-radius: 8px;
  background-color: #f2f2f2;
  box-shadow: var(--box-shadow-1);
`;

const ListItemLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding-left: 35px;

  &:hover span {
    background-color: hsl(var(--color-primary) / 50%);
    cursor: pointer;
  }
`;

const ListItemCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: hsl(var(--color-primary) / 80%);
    box-shadow: unset;
  }

  &:checked ~ span::after {
    display: block;
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: #ccc;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 50%) inset;

  &::after {
    content: '';
    position: absolute;
    display: none;

    left: 9px;
    top: 7px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

const SaveButton = styled(Button)`
  min-width: 142px;
`;

const ResetButton = styled(Button)`
  background-color: var(--color-black);
`;

export default function TransferList({ devices, groupDevices, groupId }) {
  const dispatch = useDispatch();
  const [leftBox, setLeftBox] = useState([]);
  const [rightBox, setRightBox] = useState(groupDevices);
  const isDevicesChanged = useSelector((state) => state.group.isDevicesChanged);

  useEffect(() => {
    const filteredDevices = devices
      .filter((device) => rightBox.map((d) => d._id).indexOf(device._id) === -1)
      .map((device) => ({
        ...device,
        checked: false,
      }));
    setLeftBox(filteredDevices);
    dispatch(setIsDevicesChanged(compareDevices(groupDevices, rightBox)));
  }, [rightBox]);

  function moveRight() {
    const checkedItems = leftBox
      .filter((device) => device.checked)
      .map((device) => ({
        ...device,
        checked: false,
      }));

    const updatedRightBox = [...rightBox, ...checkedItems];
    setRightBox(updatedRightBox);
  }

  function moveLeft() {
    const updatedRightBox = rightBox.filter((device) => !device.checked);
    setRightBox(updatedRightBox);
  }

  function save() {
    const requestBody = { devices: rightBox.map((device) => device._id) };
    updateGroup(groupId, requestBody)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            `Group "${res.body.group.name}" was successfully updated`
          );
          dispatch(setIsDevicesChanged(false));
        }
      })
      .catch(console.error);
  }

  function compareDevices(initial, updated) {
    const initialIds = initial.map((device) => device._id);
    const updatedIds = updated.map((device) => device._id);
    if (initialIds.length !== updatedIds.length) return true;
    return initialIds.some((id) => updatedIds.indexOf(id) === -1);
  }

  function resetChanges() {
    // Will set the right box to the initial Group Devices which will cause a re-filter of the left box too (re-render of the component)
    const updatedRightBox = groupDevices.map((device) => ({
      ...device,
      checked: false,
    }));
    setRightBox(updatedRightBox);
  }

  return (
    <>
      <TransferListWrapper>
        <BoxWrapper>
          <BoxLabel>All devices</BoxLabel>
          <Box>
            <TransferListStyles>
              {leftBox.map((device) => (
                <ListItem key={device._id}>
                  <ListItemLabel>
                    <ListItemCheckbox
                      type='checkbox'
                      value={device._id}
                      onChange={(e) => {
                        const updatedLeftBox = leftBox.map((device) => {
                          if (device._id === e.target.value) {
                            device.checked = e.target.checked;
                          }
                          return device;
                        });
                        setLeftBox(updatedLeftBox);
                      }}
                    />
                    {device.name}
                    <CheckMark />
                  </ListItemLabel>
                </ListItem>
              ))}
            </TransferListStyles>
          </Box>
        </BoxWrapper>
        <Arrows>
          <ArrowButton onClick={moveRight}>
            <FiArrowRight />
          </ArrowButton>
          <ArrowButton onClick={moveLeft}>
            <FiArrowLeft />
          </ArrowButton>
        </Arrows>
        <BoxWrapper>
          <BoxLabel>Devices in this group</BoxLabel>
          <Box>
            <TransferListStyles>
              {rightBox.map((device) => (
                <ListItem key={device._id}>
                  <ListItemLabel>
                    <ListItemCheckbox
                      type='checkbox'
                      value={device._id}
                      onChange={(e) => {
                        const updatedRightBox = rightBox.map((device) => {
                          if (device._id === e.target.value) {
                            device.checked = e.target.checked;
                          }
                          return device;
                        });
                        setRightBox(updatedRightBox);
                      }}
                    />
                    {device.name}
                    <CheckMark />
                  </ListItemLabel>
                </ListItem>
              ))}
            </TransferListStyles>
          </Box>
        </BoxWrapper>
      </TransferListWrapper>
      <ActionsWrapper>
        <SaveButton onClick={save} disabled={!isDevicesChanged}>
          {`Save${isDevicesChanged ? ' changes' : 'd'}`}
        </SaveButton>
        <ResetButton onClick={resetChanges} disabled={!isDevicesChanged}>
          Reset
        </ResetButton>
      </ActionsWrapper>
    </>
  );
}
