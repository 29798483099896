import React from 'react';
import Layout from '../components/Layout';
import CompanyRegisterForm from '../components/CompanyRegisterForm';

const CompanyRegisterPage = () => (
  <>
    <Layout>
      <CompanyRegisterForm />
    </Layout>
  </>
);

export default CompanyRegisterPage;
