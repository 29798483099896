import React from 'react';
import styled from 'styled-components';
import { LdsDualRing } from '../components/styles/Loaders';

const LoaderStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  // padding-left: var(--drawer-width);
`;

export default function Loader() {
  return (
    <>
      <LoaderStyles>
        <LdsDualRing color={'hsl(var(--color-secondary))'} />
      </LoaderStyles>
    </>
  );
}
