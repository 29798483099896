import React, { useState } from 'react';
import styled from 'styled-components';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { SelectStyles } from './styles/SelectStyles';

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const PaginationText = styled.p`
  color: #5b6473;
  font-weight: 700;
`;

const IconWrapper = styled.div`
  svg {
    display: block;
    width: 24px;
    height: 24px;
    color: #657fdf;
  }

  svg.left-arrow {
    color: ${(p) => (p.currentPage === 1 ? '#ccc' : '#657fdf')};
    cursor: ${(p) => (p.currentPage === 1 ? 'auto' : 'pointer')};
  }

  svg.right-arrow {
    color: ${(p) =>
      p.currentPage === p.totalNumberOfPages ? '#ccc' : '#657fdf'};
    cursor: ${(p) =>
      p.currentPage === p.totalNumberOfPages ? 'auto' : 'pointer'};
  }
`;

const PaginationNumber = styled.p`
  background-color: ${(p) => (p.page === p.value ? '#5b7cff' : 'transparent')};
  color: ${(p) => (p.page === p.value ? '#fff' : '#C1C8D4')};
  border-radius: 4px;
  padding: 4px 8px;
  line-height: 1;
  cursor: pointer;
`;

const LimitSelect = styled(SelectStyles)`
  margin: 0;
  padding: 5px 10px;
`;

export default function Pagination({
  totalItems,
  currentPage,
  setCurrentPage,
  limit,
  setLimit,
}) {
  const totalNumberOfPages = Math.ceil(totalItems / limit);

  function goLeft() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }

  function goRight() {
    if (currentPage === totalNumberOfPages) return;
    setCurrentPage(currentPage + 1);
  }

  return (
    <>
      <Wrapper>
        <PaginationText>Pages</PaginationText>
        <PaginationWrapper>
          <IconWrapper currentPage={currentPage}>
            <FiChevronLeft onClick={goLeft} className='left-arrow' />
          </IconWrapper>
          {totalItems > 0 &&
            Array.from(new Array(totalNumberOfPages)).map((_, index) => (
              <PaginationNumber
                key={index}
                page={currentPage}
                value={index + 1}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </PaginationNumber>
            ))}
          <IconWrapper
            currentPage={currentPage}
            totalNumberOfPages={totalNumberOfPages}
          >
            <FiChevronRight onClick={goRight} className='right-arrow' />
          </IconWrapper>
        </PaginationWrapper>
        <PaginationText>Limit</PaginationText>
        <LimitSelect onChange={(e) => setLimit(e.target.value)}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </LimitSelect>
      </Wrapper>
    </>
  );
}
