import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { apps } from '../data.json';

const Wrapper = styled.div`
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const AppBox = styled.article`
  background-color: hsl(var(--color-primary));
  color: var(--color-white);
  padding: 20px;
  width: 150px;
  height: 150px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: all 150ms ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

export default function HomePage() {
  return (
    <>
      <Layout>
        <Wrapper>
          {apps.map((app, i) => (
            <a
              key={i}
              href={app.href}
              target='_blank'
              rel='noopener, noreferrer'
            >
              <AppBox>{app.name}</AppBox>
            </a>
          ))}
        </Wrapper>
      </Layout>
    </>
  );
}
