import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FiPlus } from 'react-icons/fi';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 70%;
  margin-bottom: 50px;

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

export default function DeviceList({ devices }) {
  return (
    <>
      <Wrapper>
        {devices.length > 0 &&
          devices.map((device) => <DeviceCard {...device} key={device._id} />)}
      </Wrapper>
    </>
  );
}

function DeviceCard({ _id, name, groups, version }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div>
        <DeviceCardWrapper
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <TextWrapper isExpanded={isExpanded}>
            <DeviceName>{name}</DeviceName>
            <VersionNumber>{version?.name || '-'}</VersionNumber>
          </TextWrapper>
          <IconWrapper isExpanded={isExpanded}>
            <FiPlus />
          </IconWrapper>
        </DeviceCardWrapper>
        <Collapse isExpanded={isExpanded}>
          <GroupList>
            {!groups.length && <p>No groups assigned.</p>}
            {groups.map(({ _id, name }) => (
              <GroupLink key={_id} to={`/group/${_id}`}>
                <GroupItem>{name}</GroupItem>
              </GroupLink>
            ))}
          </GroupList>
        </Collapse>
      </div>
    </>
  );
}

const DeviceCardWrapper = styled.article`
  padding: 1rem;
  background-color: var(--color-white);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: ${(p) => (p.isExpanded ? '0rem' : '1rem')};
  border-bottom-right-radius: ${(p) => (p.isExpanded ? '0rem' : '1rem')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid hsl(var(--color-primary) / 30%);
  transition: border-radius 0.2s ease-out;
  cursor: pointer;
`;

const Collapse = styled.div`
  background-color: ${(p) =>
    p.isExpanded ? 'hsl(var(--color-primary) / 10%)' : 'transparent'};
  padding: ${(p) => (p.isExpanded ? '12px 1rem' : '0 1rem')};
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: ${(p) =>
    p.isExpanded
      ? '2px solid hsl(var(--color-primary) / 30%)'
      : '1px solid transparent'};
  border-top: none;
  height: ${(p) => (p.isExpanded ? 'auto' : 0)};
  overflow: hidden;
  opacity: ${(p) => (p.isExpanded ? 1 : 0.5)};
  transition: 0.3s ease-out;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  color: ${(p) => (p.isExpanded ? 'hsl(var(--color-primary))' : '')};
`;

const DeviceName = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
`;

const VersionNumber = styled.p`
  font-size: ${18 / 16}rem;
  color: #909090;
`;

const IconWrapper = styled.div`
  margin-top: 2px;
  transform: ${(p) => (p.isExpanded ? 'rotate(-0.125turn)' : 'rotate(0turn)')};
  transition: transform 0.2s;

  svg {
    color: #909090;
    color: ${(p) => (p.isExpanded ? 'hsl(var(--color-primary))' : '#909090')};
    width: 2rem;
    height: 2rem;
  }
`;

const GroupList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const GroupLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: revert;
  }
`;

const GroupItem = styled.li`
  font-size: ${18 / 16}rem;
  font-weight: 650;
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;
