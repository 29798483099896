import React, { useState } from 'react';
import useAuth from '../helpers/useAuth';
import styled from 'styled-components';

const LoginWrapper = styled.div`
  border: 1px solid lightgray;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;

  label,
  input {
    display: block;
  }
`;

const FormStyles = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default function LoginForm() {
  const { login } = useAuth();
  const [state, setState] = useState({
    username: '',
    password: '',
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const credentials = {
      username: state.username,
      password: state.password,
    };
    login(credentials);
  }

  return (
    <>
      <LoginWrapper>
        <h1>Login</h1>
        <FormStyles onSubmit={handleSubmit}>
          <label>
            Username:
            <input
              type='email'
              name='username'
              value={state.username}
              onChange={handleChange}
            />
          </label>
          <label>
            Password:
            <input
              type='password'
              name='password'
              value={state.password}
              onChange={handleChange}
            />
          </label>
          <input type='submit' value='send' />
        </FormStyles>
      </LoginWrapper>
    </>
  );
}
