import React from 'react';
import { useSelector } from 'react-redux';
import useAuth from '../helpers/useAuth';
import styled from 'styled-components';
import { Button } from './styles/Button';

const HeaderStyles = styled.header`
  background-color: peachpuff;
  padding: 1rem 2rem;
  margin: -2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 3px rgba(57, 63, 72, 0.4);
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
`;

export default function Header() {
  const user = useSelector((state) => state.user.user);
  const { logout } = useAuth();

  return (
    <>
      <HeaderStyles>
        <p>
          Signed in as:{' '}
          <span
            style={{ fontWeight: 700 }}
          >{`${user.fullName} (${user.username})`}</span>
        </p>
        <Button onClick={logout}>Sign out</Button>
      </HeaderStyles>
    </>
  );
}
