import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import CompanyActivation from '../components/CompanyActivation';
import config from '../config';

const Error = ({ errorHTML }) => {
  return (
    <div
      className='alert alert-danger'
      style={{ width: 'clamp(25ch, 90%, 40ch)', margin: '2rem auto' }}
      role='alert'
      dangerouslySetInnerHTML={{ __html: errorHTML }}
    />
  );
};

const Info = ({ messageHTML }) => {
  return (
    <div
      className='alert alert-info'
      style={{ width: 'clamp(25ch, 90%, 40ch)', margin: '2rem auto' }}
      role='alert'
      dangerouslySetInnerHTML={{ __html: messageHTML }}
    />
  );
};

const CompanyActivationPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const activationToken = searchParams.get('activationToken');

  useEffect(() => {
    setLoading(true);
    setError(null);

    if (activationToken) {
      fetch(config.TOKEN_VALIDATION_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ activationToken }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.error) {
            setLoading(false);
            setError('This token is either invalid or expired!');
            // setError(`${json.error}`);
            console.error(json.error);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError('Something went wrong!');
          console.error(err);
        });
    }
  }, [activationToken]);

  if (!activationToken)
    return (
      <Error errorHTML={`<p>This company activation link looks invalid.</p>`} />
    );

  if (loading) return <Info messageHTML={`<p>Validating your token...</p>`} />;

  if (error) {
    return <Error errorHTML={`<p>${error}</p>`} />;
  }

  return (
    <>
      <CompanyActivation activationToken={activationToken} />
    </>
  );
};

Error.propTypes = {
  errorHTML: PropTypes.string.isRequired,
};

Info.propTypes = {
  messageHTML: PropTypes.string.isRequired,
};

export default CompanyActivationPage;
