import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const InternalLinkButtonStyles = styled(Link)`
  background-color: hsl(var(--color-secondary));
  color: var(--color-white);
  padding: 5px 10px;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  box-shadow: var(--box-shadow-1);
`;
