import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import groupReducer from './slices/groupSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    group: groupReducer,
  },
});
