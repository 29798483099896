import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { getSingleUpdate } from '../services/updateService';

const Title = styled.h1``;

const Version = styled.p`
  span {
    font-weight: 700;
  }
`;

const Group = styled.p`
  span {
    font-weight: 700;
  }
`;

const DevicesWrapper = styled.div`
  border-radius: 6px;
  box-shadow: var(--box-shadow-1);
  background-color: var(--color-white);
  width: fit-content;
  padding: 20px;
  margin: 1rem 0;
`;

const DevicesTitle = styled.h2``;

const DevicesList = styled.ul`
  list-style-type: revert;
  padding-left: 2rem;
`;

const DeviceItem = styled.li`
  padding-left: 8px;

  &::marker {
    content: '\f108';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: hsl(var(--color-secondary));
  }
`;

export default function UpdateDetailPage() {
  const { updateId } = useParams();
  const [singleUpdate, setSingleUpdate] = useState(null);

  useEffect(() => {
    getSingleUpdate(updateId)
      .then((res) => {
        if (res.status === 200) {
          setSingleUpdate(res.body.update);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [updateId]);

  return (
    <>
      <Layout>
        <Title>{singleUpdate?.name}</Title>
        <hr />
        <Version>
          Version: <span>{singleUpdate?.version}</span>
        </Version>
        <Group>
          Group: <span>{singleUpdate?.group?.name}</span>
        </Group>
        <DevicesWrapper>
          <DevicesTitle>Devices:</DevicesTitle>
          <DevicesList>
            {singleUpdate?.devices.map((device) => (
              <DeviceItem key={device._id}>{device.name}</DeviceItem>
            ))}
          </DevicesList>
        </DevicesWrapper>
      </Layout>
    </>
  );
}
