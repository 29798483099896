// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
// https://react-redux.js.org/tutorials/quick-start

import { createSlice } from '@reduxjs/toolkit';

export const groupSlice = createSlice({
  name: 'group',
  initialState: {
    isDevicesChanged: true,
  },
  reducers: {
    setIsDevicesChanged: (state, action) => {
      state.isDevicesChanged = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsDevicesChanged } = groupSlice.actions;

export default groupSlice.reducer;
