import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import UploadPage from './pages/UploadPage';
import UpdatesPage from './pages/UpdatesPage';
import UpdateDetailPage from './pages/UpdateDetailPage';
import GroupsPage from './pages/GroupsPage';
import GroupDetailPage from './pages/GroupDetailPage';
import DevicesPage from './pages/DevicesPage';
import CompanyRegisterPage from './pages/CompanyRegisterPage';
import CreateGroupPage from './pages/CreateGroupPage';
import CompanyActivationPage from './pages/CompanyActivationPage';
import ActivationSuccessPage from './pages/ActivationSuccessPage';
import InvitePage from './pages/InvitePage';
import { useSelector } from 'react-redux';
import useAuth from './helpers/useAuth';
import Loader from './components/Loader';

export default function RouterPage() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [loading, setLoading] = useState(true);
  const { rehydrateUser } = useAuth();

  useEffect(() => {
    rehydrateUser(setLoading);
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/'>
            {!isLoggedIn ? <LoginPage /> : <HomePage />}
          </Route>
          <Route exact path='/upload'>
            <UploadPage />
          </Route>
          <Route exact path='/update/:updateId'>
            <UpdateDetailPage />
          </Route>
          <Route exact path='/updates'>
            <UpdatesPage />
          </Route>
          <Route exact path='/devices'>
            <DevicesPage />
          </Route>
          <Route exact path='/groups/new'>
            <CreateGroupPage />
          </Route>
          <Route exact path='/group/:groupId'>
            <GroupDetailPage />
          </Route>
          <Route exact path='/groups'>
            <GroupsPage />
          </Route>
          <Route exact path='/company-register'>
            <CompanyRegisterPage />
          </Route>
          <Route exact path='/company-activation'>
            <CompanyActivationPage />
          </Route>
          <Route exact path='/company-activation-success'>
            <ActivationSuccessPage />
          </Route>
          <Route exact path='/invite'>
            <InvitePage />
          </Route>
        </Switch>
      </Router>
    </>
  );
}
