import styled from 'styled-components';

export const LdsDualRing = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  &::after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid hsl(var(--color-secondary));
    border-color: hsl(var(--color-secondary)) transparent
      hsl(var(--color-secondary)) transparent;
    animation: lds-dual-ring 0.5s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LdsHourglass = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  &::after {
    content: ' ';
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: ${(p) => `32px solid ${p.color || '#fff'}`};
    border-color: ${(p) =>
      `${p.color || '#fff'} transparent ${p.color || '#fff'} transparent`};
    animation: lds-hourglass 1.2s infinite;
  }

  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
`;
