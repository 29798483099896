import React from 'react';
import Invite from '../components/Invite';

const InvitePage = () => {
  return (
    <>
      <Invite />
    </>
  );
};

export default InvitePage;
