import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* End of CSS Reset */

  *, *::before, *::after {
    box-sizing: border-box
  }

  html, body, #app {
    height: 100%;
  }

  html {
    --reach-dialog: 1;
    --font-family: 'Asap',-apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

    --color-black: #2e2e2e;
    /* --color-primary: #00486c; */
    --color-primary: 200 100% 21%;
    /* --color-secondary: #d4882f; */
    --color-secondary: 32 66% 51%;
    /* --color-background: #f0f2f5; */
    --color-background: #E7EFF8;
    --color-white: #fff;
    --color-error: 0 100% 64%;

    --drawer-width: 250px;
    --box-shadow-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    /* overflow-y: scroll; */
  }

  body {
    font-family:  var(--font-family);
    color: var(--color-black);
    background-color: var(--color-background);
    line-height: 1.625;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  
  input[type="submit"] {
    border: none;
    padding: 8px 16px;
    background-color: hsl(var(--color-primary));
    color:var(--color-white);
    cursor: pointer;
    border-radius: 8px;
    font-family: var(--font-family);
    font-size: 16px;
    text-transform: uppercase;
  }

  :is(input[type="submit"]):disabled {
    background-color: lightgray;
    cursor: auto;
  }

  input:not([type="checkbox"]) {
    display: block;
    padding: 10px;
    min-width: 350px;
    font-size: 18px;
    border-radius: 8px;
    border: 1px solid lightgray;
  }

  td, th {
    width: 8rem;
    height: 2rem;
    border: 1px solid #ccc;
    text-align: center;
  }
  th {
    background: lightblue;
    border-color: white;
  }

`;

export default GlobalStyle;
