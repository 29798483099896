import { client } from '../api/api-client';
import config from '../config';

function signIn(credentials) {
  return client(config.BASE_AUTH_URL, 'users/login', { body: credentials });
}

function getUser() {
  return client(config.BASE_AUTH_URL, 'users/me');
}

export { signIn, getUser };
