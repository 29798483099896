import { client } from '../api/api-client';
import config from '../config';

function getGroups(currentPage = 1, limit = 10) {
  return client(
    config.UPDATE_SERVER_URL,
    `groups?page=${currentPage}&limit=${limit}`
  );
}

function getSingleGroup(groupId) {
  return client(config.UPDATE_SERVER_URL, `groups/${groupId}`);
}

function createGroup(body) {
  return client(config.UPDATE_SERVER_URL, `groups/new`, { body });
}

function deleteGroup(groupId) {
  return client(config.UPDATE_SERVER_URL, `groups/${groupId}`, {
    method: 'DELETE',
  });
}

function updateGroup(groupId, body) {
  return client(config.UPDATE_SERVER_URL, `groups/${groupId}`, {
    body,
    method: 'PUT',
  });
}

export { getGroups, getSingleGroup, createGroup, deleteGroup, updateGroup };
