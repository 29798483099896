import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { LabelStyles } from './styles/LabelStyles';
import { Button } from './styles/Button';
import config from '../config';
import constants, { errorMessages } from '../helpers/constants';

const Wrapper = styled.div`
  background-color: #fff;
  max-width: 700px;
  margin: 2rem auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow-1);
`;

export default function CompanyActivation({ activationToken }) {
  const history = useHistory();
  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: '',
  });
  const [error, setError] = useState({
    state: false,
    message: '',
  });

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = async (data) => {
    setLoading(true);
    fetch(`${config.ACTIVATION_URL}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password: data.password,
        confirmPassword: data.confirmPassword,
        address: data.address,
        activationToken,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json.error) {
          setLoading(false);
          setError({ state: true, message: `<p>${json.error}<p>` });
        } else {
          // setSuccess({ state: true, message: `<p>${response.message}</p>` });
          window.localStorage.setItem(constants.LOCALSTORAGE_KEY, json.token);
          history.push('/company-activation-success');
        }
      })
      .catch((err) => {
        setLoading(false);
        setError({
          state: true,
          message: `<p>${errorMessages.SOMETHING_WENT_WRONG}<p>`,
        });
        console.log(err);
      });
  };

  return (
    <Wrapper>
      <form noValidate>
        <h2>Company activation</h2>
        <p>Please add a new password for your account</p>
        <hr className={loading ? `loading` : ``} />
        {success.state && (
          <div
            role='alert'
            dangerouslySetInnerHTML={{ __html: success.message }}
          ></div>
        )}
        {error.state && (
          <div
            role='alert'
            dangerouslySetInnerHTML={{ __html: error.message }}
          ></div>
        )}
        <fieldset disabled={loading}>
          <LabelStyles errors={errors?.password}>
            Password
            <input
              type='password'
              id='password'
              {...register('password', {
                required: 'You must specify a password',
                pattern: {
                  value: constants.regexPatterns.MIDDLE_PASSWORD,
                  message: `Your password must be at least 8 characters long,
                    contain at least one number and have a mixture of
                    uppercase and lowercase letters.`,
                },
              })}
            />
            <span>{(errors.password && errors.password.message) || '.'}</span>
          </LabelStyles>
          <LabelStyles errors={errors?.confirmPassword}>
            Confirm Password
            <input
              type='password'
              id='confirmPassword'
              {...register('confirmPassword', {
                validate: (value) =>
                  value === password.current || 'The passwords do not match',
              })}
            />
            <span>
              {(errors.confirmPassword && errors.confirmPassword.message) ||
                '.'}
            </span>
          </LabelStyles>
          <LabelStyles errors={errors?.address}>
            Company Address
            <input
              type='text'
              id='address'
              placeholder='Eg. 1234 Budapest, Fő u. 1.'
              {...register('address', {
                required: 'Company name cannot be empty.',
              })}
            />
            <span>{(errors.address && errors.address.message) || '.'}</span>
          </LabelStyles>
          <div>
            <Button type='submit' onClick={handleSubmit(onSubmit)}>
              Send
            </Button>
          </div>
        </fieldset>
      </form>
    </Wrapper>
  );
}

CompanyActivation.propTypes = {
  activationToken: PropTypes.string,
};
