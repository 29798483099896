import { client } from '../api/api-client';
import config from '../config';
import { toast } from 'react-toastify';
import constants, { errorMessages } from '../helpers/constants';

function uploadUpdate(body) {
  const token = window.localStorage.getItem(constants.LOCALSTORAGE_KEY);
  const headers = {};
  if (token) {
    headers.Authorization = token;
  }
  const method = 'POST';

  const formData = new FormData();
  formData.append('name', body.name);
  formData.append('version', body.version);
  formData.append('file', body.file);
  formData.append('checksum', body.checksum);
  formData.append('group', body.group);

  return fetch(`${config.UPDATE_SERVER_URL}/updates/new`, {
    method,
    headers,
    body: formData,
  })
    .then(async (res) => {
      if (!res.ok) {
        const body = await res.json();
        toast.error(errorMessages[body.error]);
        return {
          status: res.status,
          body,
        };
      }

      if (res.ok) {
        const body = await res.json();
        return {
          status: res.status,
          body,
        };
      } else {
        const errorMessage = await res.text();
        return Promise.reject(new Error(errorMessage));
      }
    })
    .catch((err) => {
      console.error(err);
      const errorMessage = JSON.parse(err.message).error;
      toast.error(errorMessages[errorMessage]);
    });
}

function getUpdates(currentPage, limit) {
  return client(
    config.UPDATE_SERVER_URL,
    `updates?page=${currentPage}&limit=${limit}`
  );
}

function getSingleUpdate(updateId) {
  return client(config.UPDATE_SERVER_URL, `updates/${updateId}`);
}

function deleteUpdate(updateId) {
  return client(config.UPDATE_SERVER_URL, `updates/${updateId}`, {
    method: 'DELETE',
  });
}

export { uploadUpdate, getUpdates, deleteUpdate, getSingleUpdate };
