import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Navigation from './Navigation';

const MainContent = styled.main`
  margin-left: var(--drawer-width);
  padding: 2rem;
`;

export default function Layout({ children }) {
  return (
    <>
      <Navigation />
      <MainContent>
        <Header />
        {children}
      </MainContent>
    </>
  );
}
