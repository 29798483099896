import React, { useEffect, useState } from 'react';
import { getDevices } from '../services/deviceService';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import DeviceList from '../components/DeviceList';
import Pagination from '../components/Pagination';

export default function DevicesPage() {
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [count, setCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setLoading(true);
    getDevices(currentPage, limit)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setDevices(response.body.devices);
          setCount(response.body.count);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [currentPage, limit]);

  return (
    <>
      <Layout>
        <Pagination
          totalItems={count}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
        {loading && <Loader />}
        {!devices.length && !loading && <h1>No device was found.</h1>}
        <DeviceList devices={devices} />
      </Layout>
    </>
  );
}
