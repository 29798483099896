import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { LabelStyles } from './styles/LabelStyles';
import constants from '../helpers/constants';
import ToggleSwitch from './ToggleSwitch';
import { registerCompany } from '../services/companyService';

export default function CompanyRegisterForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    console.log(data);
    registerCompany(data)
      .then((res) => {
        console.log('component success', res);
        toast.success('Success!');
      })
      .catch((err) => {
        console.error('component', err);
      });
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <LabelStyles errors={errors?.companyName}>
            Company name:
            <input
              type='text'
              {...register('companyName', { required: 'Required field' })}
            />
            <span>
              {(errors.companyName && errors.companyName.message) || '.'}
            </span>
          </LabelStyles>
          <LabelStyles errors={errors?.email}>
            Email:
            <input
              type='email'
              {...register('email', { required: 'Required field' })}
            />
            <span>{(errors.email && errors.email.message) || '.'}</span>
          </LabelStyles>
          <LabelStyles errors={errors?.vatId}>
            VAT Registration Number:
            <input
              type='text'
              {...register('vatId', {
                required: 'Required field',
                pattern: {
                  value: constants.regexPatterns.VAT_REG_NO,
                  message: 'Format: 12345678-2-41',
                },
              })}
            />
            <span>{(errors.vatId && errors.vatId.message) || '.'}</span>
          </LabelStyles>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
              fontWeight: 700,
            }}
          >
            <h4 style={{ minWidth: 150 }}>DSS</h4>
            <ToggleSwitch register={register} name='dss' />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
              fontWeight: 700,
            }}
          >
            <h4 style={{ minWidth: 150 }}>VMS</h4>
            <ToggleSwitch register={register} name='vms' />
          </div>
          <input type='submit' value='send' />
        </fieldset>
      </form>
    </>
  );
}
