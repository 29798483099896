export default {
  LOCALSTORAGE_KEY: 'access-token',
  regexPatterns: {
    VERSION_NUMBER: /^\d{1,}\.\d{1,}\.\d{1,}[a-z]*$/i,
    // VAT_REG_NO: /^\d{8}-\d{1}-\d{2}$/i,
    VAT_REG_NO: /^[H][U]\d{8}$/i,
    MIDDLE_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
};

export const errorMessages = {
  this_version_already_exists: 'This version already exists.',
  file_must_zip: 'File must be a .zip file!',
  SOMETHING_WENT_WRONG: 'Something went wrong! Please try again.',
};
