import styled from 'styled-components';

export const LabelStyles = styled.label`
  font-weight: 700;
  color: ${(p) => (p.errors ? 'red' : 'inherit')};

  span {
    color: ${(p) => (!p.errors ? 'transparent' : 'red')};
    margin: 0.5rem 0;
    display: block;
  }

  input[type='checkbox'] {
    display: inline;
  }
`;
