import React from 'react';
import LoginForm from '../components/LoginForm';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
  background-color: hsl(var(--color-primary));
`;

export default function LoginPage() {
  return (
    <>
      <Wrapper>
        <LoginForm />
      </Wrapper>
    </>
  );
}
