import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LabelStyles } from '../components/styles/LabelStyles';
import Layout from '../components/Layout';
import { getDevices } from '../services/deviceService';
import { createGroup } from '../services/groupService';
import { toast } from 'react-toastify';

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const slideInFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const Box = styled.div`
  border-radius: 8px;
  background-color: var(--color-white);
  padding: 1rem;
  height: 300px;
  box-shadow: var(--box-shadow-1);
  overflow-y: auto;
`;

const TransferListStyles = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ListItem = styled.li`
  padding: 12px;
  border-radius: 8px;
  background-color: #f2f2f2;
  box-shadow: var(--box-shadow-1);
`;

const ListItemLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  padding-left: 35px;

  &:hover span {
    background-color: hsl(var(--color-primary) / 50%);
    cursor: pointer;
  }
`;

const ListItemCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: hsl(var(--color-primary) / 80%);
    box-shadow: unset;
  }

  &:checked ~ span::after {
    display: block;
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: #ccc;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 50%) inset;

  &::after {
    content: '';
    position: absolute;
    display: none;

    left: 9px;
    top: 7px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const DeviceSelectorWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(400px, 100%), 1fr));
  gap: 2rem;
  margin-bottom: 1rem;
`;

const SelectedDevicesTitle = styled.h2`
  margin-top: -4px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
  animation: ${slideIn} 0.35s ease;
`;

const SelectedList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 1.5rem;
`;

const SelectedItem = styled.li`
  padding-left: 1rem;
  animation: ${slideInFromRight} 0.35s;

  &::marker {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f108';
    color: hsl(var(--color-secondary));
  }
`;

export default function CreateGroupPage() {
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDevices()
      .then((res) => {
        if (res.status === 200) {
          const updatedDevices = res.body.devices.map((device) => ({
            ...device,
            checked: false,
          }));
          setDevices(updatedDevices);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const selected = devices
      .map((device) => {
        if (device.checked) return device;
      })
      .filter((e) => e?._id);
    setSelectedDevices(selected);
  }, [devices]);

  function onSubmit(data) {
    // Get the keys but filter out "name"
    // const keys = Object.keys(data).filter((e) => e !== 'name');

    // Filter out the unchecked ones
    // const devices = keys.filter((key) => data[key]);
    const reqBody = {
      name: data.name,
      devices: selectedDevices.map((d) => d._id),
    };

    createGroup(reqBody)
      .then((res) => {
        if (res.status === 201) {
          toast.success(
            `Group ${res.body.group._id} was successfully created!`
          );
          history.push('/groups');
        }
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <Layout>
        <h1>Add New Group</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <LabelStyles errors={errors?.name}>
              Group name:
              <input
                type='text'
                {...register('name', { required: 'Required field' })}
              />
              <span>{(errors.name && errors.name.message) || '.'}</span>
            </LabelStyles>
            <DeviceSelectorWrapper>
              <Box>
                <TransferListStyles>
                  {devices.map((device, index) => (
                    <ListItem key={device._id}>
                      <ListItemLabel>
                        <ListItemCheckbox
                          type='checkbox'
                          value={device._id}
                          onChange={(e) => {
                            const updatedList = devices.map((device) => {
                              if (device._id === e.target.value) {
                                device.checked = e.target.checked;
                              }
                              return device;
                            });
                            setDevices(updatedList);
                          }}
                        />
                        {device.name}
                        <CheckMark />
                      </ListItemLabel>
                    </ListItem>
                  ))}
                </TransferListStyles>
              </Box>
              <div>
                {selectedDevices.length > 0 && (
                  <>
                    <SelectedDevicesTitle>
                      Selected devices
                    </SelectedDevicesTitle>
                    <SelectedList>
                      {selectedDevices.map((selectedDevice) => (
                        <SelectedItem key={selectedDevice?._id}>
                          {selectedDevice?.name}
                        </SelectedItem>
                      ))}
                    </SelectedList>
                  </>
                )}
              </div>
            </DeviceSelectorWrapper>
            <input type='submit' value='send' />
          </fieldset>
        </form>
      </Layout>
    </>
  );
}
