import React from 'react';
import GlobalStyle from './styles/GlobalStyle';
import Router from './Router';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <>
      <GlobalStyle />
      <Router />
      <ToastContainer
        position='top-center'
        autoClose={5000}
        transition={Slide}
      />
    </>
  );
}
