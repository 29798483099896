import styled from 'styled-components';

export const CardStyles = styled.div`
  border: 2px solid darkgray;
  padding: 1.5rem;
  background-color: var(--color-white);
  border-radius: 8px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  ul {
    list-style-type: square;
    padding-left: 1rem;
  }
`;
