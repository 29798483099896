import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Layout from '../components/Layout';
import constants from '../helpers/constants';
import { getChecksum } from '../helpers/getChecksum';
import { uploadUpdate } from '../services/updateService';
import { getGroups } from '../services/groupService';
import { LabelStyles } from '../components/styles/LabelStyles';
import { SelectStyles } from '../components/styles/SelectStyles';

export default function UploadPage() {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checksum, setChecksum] = useState('');
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    setLoading(true);
    getGroups().then((res) => {
      if (res.body.success) {
        setGroups(res.body.groups);
        setLoading(false);
      }
    });
  }, []);

  function handleChange(e) {
    const file = e.target.files[0];
    getChecksum(file, setChecksum);
    console.log(file.type);
    if (!file.type.includes('zip')) {
      setError('File type not supported');
    } else {
      setError(null);
    }
  }

  function onSubmit(data) {
    const reqBody = {
      name: data.updateName,
      version: data.versionNumber,
      file: data.updateFile[0],
      checksum,
      group: data.group,
    };

    console.log({ reqBody });

    uploadUpdate(reqBody)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          reset();
          toast.success(
            `Update "${response.body.update.name}(${response.body.update.version})" was successfully uploaded`
          );
        }
      })
      .catch(console.error);
  }

  return (
    <>
      <Layout>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={loading}>
            <LabelStyles errors={errors?.updateName}>
              Name
              <input
                type='text'
                {...register('updateName', { required: 'Required field' })}
              />
              <span>
                {(errors.updateName && errors.updateName.message) || '.'}
              </span>
            </LabelStyles>
            <LabelStyles errors={errors?.versionNumber}>
              Version Number
              <input
                type='text'
                {...register('versionNumber', {
                  required: 'Required field',
                  pattern: {
                    value: constants.regexPatterns.VERSION_NUMBER,
                    message: 'Format: 1x.0x.0xAnyAlphabeticCharacters',
                  },
                })}
              />
              <span>
                {(errors.versionNumber && errors.versionNumber.message) || '.'}
              </span>
            </LabelStyles>
            <LabelStyles>
              Group
              <SelectStyles
                {...register('group', { required: 'One Group is required' })}
                disabled={loading}
              >
                <option value=''>--Please choose a group--</option>
                {groups.map((group) => (
                  <option key={group._id} value={group._id}>
                    {group.name}
                  </option>
                ))}
              </SelectStyles>
              <span>{(errors.group && errors.group.message) || '.'}</span>
            </LabelStyles>
            <LabelStyles errors={errors?.updateFile}>
              <input
                type='file'
                accept='.zip'
                {...register('updateFile', {
                  required: 'File is required and should be a zip file',
                })}
                onChange={handleChange}
              />
              <span>
                {(errors.updateFile && errors.updateFile.message) || '.'}
              </span>
            </LabelStyles>
            {error && <p>{error}</p>}
            <input type='submit' value='send' disabled={Boolean(error)} />
          </fieldset>
        </form>
      </Layout>
    </>
  );
}
