import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { getGroups, deleteGroup } from '../services/groupService';
import { CardStyles } from '../components/styles/CardStyles';
import Loader from '../components/Loader';
import ReusableModal from '../components/ReusableModal';
import { InternalLinkButtonStyles } from '../components/styles/InternalLinkButtonStyles';
import { Button } from '../components/styles/Button';
import Pagination from '../components/Pagination';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 80%;

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

const GroupName = styled.h3`
  flex: 2;
`;

const DetailsButton = styled(InternalLinkButtonStyles)`
  flex: 1;
`;

const AddNewButton = styled(InternalLinkButtonStyles)`
  display: inline-block;
  margin-bottom: 1rem;
`;

export default function GroupsPage() {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupToBeDeleted, setGroupToBeDeleted] = useState(null);
  const [groupNameToBeDeleted, setGroupNameToBeDeleted] = useState(null);
  const [count, setCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setLoading(true);
    getGroups(currentPage, limit)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setGroups(res.body.groups);
          setCount(res.body.count);
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(`Something went wrong!`);
        setLoading(false);
      });
  }, [currentPage, limit]);

  function openDeletionModal(group) {
    setIsModalOpen(true);
    setGroupToBeDeleted(group._id);
    setGroupNameToBeDeleted(group.name);
  }

  function closeDeletionModal() {
    setIsModalOpen(false);
    setGroupToBeDeleted(null);
    setGroupNameToBeDeleted(null);
  }

  function handleDelete() {
    deleteGroup(groupToBeDeleted)
      .then((res) => {
        if (res.status === 200) {
          const updatedList = groups.filter(
            (group) => group._id !== groupToBeDeleted
          );
          setGroups(updatedList);
          toast.success(
            `Group named "${groupNameToBeDeleted}" was successfully deleted`
          );
          setIsModalOpen(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      <Layout>
        {loading && <Loader />}
        <AddNewButton to='/groups/new'>New Group</AddNewButton>
        <Pagination
          totalItems={count}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
        />
        <Wrapper>
          {!loading &&
            groups.length > 0 &&
            groups.map((group) => (
              <CardStyles key={group._id}>
                <GroupName>{group.name}</GroupName>
                <DetailsButton to={`/group/${group._id}`}>
                  Details
                </DetailsButton>
                <Button
                  onClick={() => openDeletionModal(group)}
                  style={{ flex: 1 }}
                >
                  Delete
                </Button>
              </CardStyles>
            ))}
        </Wrapper>
      </Layout>
      <ReusableModal isOpen={isModalOpen} close={closeDeletionModal}>
        <p>Do you really want to delete group "{groupNameToBeDeleted}"?</p>
        <div style={{ display: 'flex', marginTop: '1rem', gap: '1rem' }}>
          <Button
            onClick={closeDeletionModal}
            style={{
              border: '1px solid hsl(var(--color-primary))',
              backgroundColor: 'var(--color-white)',
              color: 'hsl(var(--color-primary))',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            style={{ border: '1px solid hsl(var(--color-primary))' }}
          >
            Delete
          </Button>
        </div>
      </ReusableModal>
    </>
  );
}
