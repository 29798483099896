import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import constants from '../helpers/constants';
import { LabelStyles } from './styles/LabelStyles';
import { Button } from './styles/Button';
import config from '../config'

const Wrapper = styled.div`
  background-color: #fff;
  max-width: 700px;
  margin: 2rem auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow-1);
`;

const PlusIcon = styled(BsFillPlusCircleFill)`
  display: block;
  margin: 1rem 0;
  cursor: pointer;
`;

export default function Invite() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  });

  const [counter, setCounter] = useState(3);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSucess] = useState({ state: false, html: `` });
  const [emails, setEmails] = useState([]);

  function incrementInput() {
    setCounter(counter + 1);
  }

  function onSubmit(data) {
    setError(null);
    let emails = Object.values(data);
    emails = emails.filter((email) => email !== '');
    const uniqueEmails = [...new Set(emails)];
    setEmails(uniqueEmails);
    const emailList = `<ul>
        ${uniqueEmails.map((mail) => `<li key=${mail}>${mail}</li>`)}
      </ul>`;
    console.log(emailList);

    // TODO:
    // 1. Ha üres a lista, írd ki egy Alert-ban, hogy egy emailt sem írt be
    if (!uniqueEmails.length) {
      setError(`
        <b>Error!</b>
        <p>You need to add at least one email address.</p>
      `);
    }

    // 2. Ha nem üres, írd ki egy Info Alert-ban az egyedi email címeket, hogy ezeknek fog
    if (uniqueEmails.length) {
      setInfo(`
        <p>You are going to send invitation for the following email addresses:</p>
        ${emailList}
      `);
    }
    //    meghívót küldeni + egy gombot, amivel elküldheti
    // 3. handleSendInvites() fv megírása
    // 4. Kiírni egy success Alert-ban, ha sikeres, vagy Error-ban, ha nem.
  }

  function handleSendInvites() {
    setLoading(true);
    const token = window.localStorage.getItem(constants.LOCALSTORAGE_KEY);
    fetch(config.INVITE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({ emails }),
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);
        if (json.error) {
          console.error(json.error);
          setError(`
            <b>Error!</b>
            <p>${json.error}</p>
          `)
        } else {
          setLoading(false);
          console.log('SUCCESS', json);
          setSucess({
            state: true,
            html: `<h5></strong>🥳 Invitations successfully sent!</strong><h5>`,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }

  return (
    <>
      <Wrapper>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <h2>Invite colleagues</h2>
          <hr className={loading ? `loading` : ``} />
          {success.state && <Success successHTML={success.html} />}
          {error && <Error errorHTML={error} />}
          <fieldset disabled={loading}>
            {Array.from(new Array(counter)).map((_, index) => (
               <LabelStyles key={index} errors={errors?.[`email-${index}`]}>
                 E-mail
                 <input
                   type='email'
                   id={`email-${index}`}
                   {...register(`email-${index}`, {
                     pattern: {
                       value: constants.regexPatterns.EMAIL,
                       message: 'Invalid email address',
                     },
                   })}
                 />
                 <span>{(errors.[`email-${index}`] && errors[`email-${index}`]?.message) || '.'}</span>
               </LabelStyles>
            ))}
            <PlusIcon size={25} onClick={incrementInput} />
            <div className='form-group'>
              <Button type='submit'>
                OK
              </Button>
            </div>
          </fieldset>
          {info && (
            <InfoSection
              infoHTML={info}
              handleSendInvites={handleSendInvites}
            />
          )}
        </form>
      </Wrapper>
    </>
  );
}

const InfoSection = ({ infoHTML, handleSendInvites }) => {
  const InfoStyles = styled.div`
    border: 2px solid darkcyan;
    padding: 20px;
    margin: 1rem 0;
    border-radius: 8px;
  `;

  return (
    <>
      <InfoStyles
        className='alert alert-info'
        role='alert'
        dangerouslySetInnerHTML={{ __html: infoHTML }}
      />
      <div className='form-group'>
        <Button
          onClick={handleSendInvites}
          type='submit'
          className='btn btn-secondary btn-lg'
        >
          SEND
        </Button>
      </div>
    </>
  );
};

const Error = ({ errorHTML }) => {
  const ErrorStyles = styled.div`
    border: 2px solid red;
    padding: 20px;
    margin: 1rem 0;
    border-radius: 8px;
  `;

  return (
    <ErrorStyles
      role='alert'
      dangerouslySetInnerHTML={{ __html: errorHTML }}
    />
  );
};

const Success = ({ successHTML }) => {
  const SuccessStyles = styled.div`
    border: 2px solid green;
    padding: 20px;
    margin: 1rem 0;
    border-radius: 8px;
  `;

  return (
    <SuccessStyles
      role='alert'
      dangerouslySetInnerHTML={{ __html: successHTML }}
    />
  );
};
